import React, { Component } from "react"
import "../scss/careers.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryImg from "../components/gallery-img"
import { setDefaultHeaderTheme, getWorkBenefits } from "../utils/common-util"

class CareersPage extends Component {
  componentDidMount() {
    setDefaultHeaderTheme(true)
  }

  render() {
    const benefits = getWorkBenefits()

    return (
      <Layout>
        <SEO title="Careers" />
        <div className="crr-top-content iww-full-height">
          <h1 className="top-heading">Life at Integration Wizards</h1>
          <div id="stars1" className="stars"></div>
          <div id="stars2" className="stars"></div>
          <div id="stars3" className="stars"></div>
          {/* <div className="top-desc">Be part of something big. We’ve got an incredible team and amazing growth. But it’s not just about us, it’s about you.</div>
          <div className="top-text">Working at Integration Wizards is fun with an opportunity to make your mark. A typical day here will be filled with projects and challenges. So whether you’re an intern, a fresh grad or experienced, you'll be part of a team that encourages learning and collaboration.</div> */}
        </div>
        <div className="work-benefits-content iww-container">
          <div className="wb-title">Values We Take Pride In</div>
          <div className="wb-desc">
            A fast-growing company with a niche in cutting-edge technologies can
            offer some wonderful work benefits.
          </div>
          <div className="work-benefits">
            {benefits.map(ben => {
              return (
                <div
                  key={ben.key}
                  className={"wb-row" + (ben.swipe ? " wb-swipe" : "")}
                >
                  <div className="wb-gap"></div>
                  <div className="benefit">
                    <div className={"benefit-icon " + ben.icon}></div>
                    <div className="benefit-title">{ben.label}</div>
                    <div className="benefit-desc">{ben.desc}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="crr-links">
            <div className="current-job-text">
              Looking for extraordinary people to join our team that are keen on
              defining the future of technologies. Find the right role for you.
            </div>
            <OutboundLink
              href="https://www.linkedin.com/company/integration-wizards-solutions-pvt-ltd"
              rel="noopener noreferrer"
              target="_blank"
              className="button is-primary is-large"
            >
              See jobs
            </OutboundLink>
            <div className="didnt-find-text">
              Didn’t find what you are looking for ? Send us your resumes at
            </div>
            <div className="job-mailid">hr@iwizardsolutions.com</div>
          </div>
        </div>
        <div className="gallery-content iww-container">
          <div className="gallery-title">
            A SNEAK PEEK INTO OUR WORK CULTURE
          </div>
          <div className="gallery-images">
            <div className="glr-image img-11">
              <GalleryImg name="img11"></GalleryImg>
            </div>
            <div className="glr-image img-12">
              <GalleryImg name="img12"></GalleryImg>
            </div>
            <div className="glr-image img-21">
              <GalleryImg name="img21"></GalleryImg>
            </div>
            <div className="glr-image img-22">
              <GalleryImg name="img22"></GalleryImg>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CareersPage
