import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const GalleryImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      img11: file(relativePath: { eq: "gallery/cristam-group.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3276) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img12: file(relativePath: { eq: "gallery/drone-Image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4288) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img21: file(relativePath: { eq: "gallery/devices.JPEG" }) {
        childImageSharp {
          fluid(maxWidth: 2016) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img22: file(relativePath: { eq: "gallery/diwali-rangoli.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 819) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default GalleryImg
